const contentRoot = document.querySelector('#ionic-content-root');
const env = contentRoot?.getAttribute('data-environment') ?? process.env.REACT_APP_ENVIRONMENT ?? process.env.NODE_ENV;
const isProduction = env === 'production';

function checkBool(value: any): boolean {
    return `${value}` === 'true' || `${value}` === '1';
}

export const environment = {
    apiToken: contentRoot?.getAttribute('data-api-token') ?? null,
    powderguideWebsiteUrl: contentRoot?.getAttribute('data-powderguide-website-url') ?? process.env.REACT_APP_POWDERGUIDE_WEBSITE_URL ?? (isProduction ? 'https://powderguide.com' : 'https://powderguide.stage.croox.dev'),
    isProduction: isProduction,
    googleMapsApiKey: contentRoot?.getAttribute('data-google-maps-api-key') ?? process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyBYHQ-3Eiv92Nu_c_FwpSn5RtX7y5yX1_4',
    version: process.env.REACT_APP_VERSION ?? 'unknown_version',
    baseUrl: contentRoot?.getAttribute('data-embedded-base') ?? process.env.POWDERGUIDE_BASE_URL ?? '/',

    // The web app can be run in an embedded mode, where it is loaded inside of an existing
    // website. We can detect this, by checking if special meta tags are *not* present.
    // When in embedded mode, the website should provide a base URL for the app to use.
    isEmbedded: document.querySelector('meta[name="powderguide-embedded-app"][content="false"]') === null,

    // sentryDsn: 'https://f68297dc5623d3ad58a8f733c6913304@o4506423012163584.ingest.us.sentry.io/4507067324563456',
    sentryDsn: contentRoot?.getAttribute('data-sentry-dsn') ?? 'https://e715f90229ff4804b7a040f9c4190aab@app.glitchtip.com/7891',

    enableMiniCrEditing: checkBool(process.env.REACT_APP_ENABLE_MINI_CR_EDITING),
};

export enum COLORS {
    GREY = '#E0E0E0',
    BLUE = '#00234c',
    LightBlue = '#00a5eb'
}
