import React from 'react';
import {Storage} from '@ionic/storage';
import {apiClient} from '../data/api';
import _labels from './labels.json';
import {Device} from "@capacitor/device";

export type Language = 'de' | 'en';
const activeLanguages: Language[] = [ 'de', 'en' ];
const defaultLanguage: Language = 'en' as const;
type DefaultLanguage = typeof defaultLanguage;

const labels: Record<string, Partial<Record<Language, string | null>> & Record<DefaultLanguage, string>> = _labels;
export type LanguageLabel = keyof typeof labels;
export type LabelFunc = (label: LanguageLabel, replacements?: Record<string, string>, lang?: Language) => string;

export function getLabel(
    label: LanguageLabel,
    locale: Language,
    replacements: Record<string, string> = {},
): string {
    let translated: string = labels?.[label]?.[locale] ?? labels?.[label]?.[defaultLanguage] ?? label;
    for (const [key, value] of Object.entries(replacements)) {
        translated = translated.replaceAll(`{${key}}`, value);
    }
    return translated;
}


interface LanguageStateContext {
    locales: Language[],
    locale: Language,
    setLocale: (locale: Language) => void,
    label: LabelFunc,
}

const LanguageStateContext = React.createContext<LanguageStateContext | null>(null);

export const LanguageStateContextProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({children}) => {
    const LOCALE_KEY = 'locale';
    const [locale, setLocale] = React.useState<Language>(defaultLanguage);
    const [storage] = React.useState<Storage>(new Storage());

    const label = (label: LanguageLabel, replacements: Record<string, string> = {}, lang?: Language) => getLabel(label, lang ?? locale, replacements);

    const updateLocale = (locale: Language) => {
        storage!.set(LOCALE_KEY, locale);
        setLocale(locale);
        apiClient.setLocale(`${locale}`);
    };

    React.useEffect(() => {
      storage.create();

      storage.get(LOCALE_KEY).then((locale: Language | null) => {
        if (locale) {
          setLocale(locale);
          return;
        }

        Device.getLanguageCode().then(code => {
          const lang = code.value as Language;
          if (activeLanguages.includes(lang)) {
            updateLocale(lang);
          }
        })
      })
    }, [ ])

    return (
        <LanguageStateContext.Provider value={{locale, setLocale: updateLocale, label, locales: activeLanguages }}>
            {children}
        </LanguageStateContext.Provider>
    );
};

export function useI18n(): LanguageStateContext {
    const context = React.useContext(LanguageStateContext);
    if (context === null) {
        throw new Error('useLocale must be used within a LanguageStateContextProvider');
    }
    return context;
}
