import {PageWrapper} from '../../components/PageWrapper';
import {
    IonButton,
    IonGrid,
    IonImg,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonLoading,
    IonRow,
    IonThumbnail,
} from '@ionic/react';
import React from 'react';
import {StarSelector} from '../../components/StarSelector';
import {useLoginState} from '../../data/Login';
import {TemporaryImagePreview, TemporaryVideoPreview} from '../../components/TemporaryFilepreview';
import {ConditionsReport} from '../../data/ConditionsReport';
import {TemporaryVideo} from '../../data/videos';
import {ConditionsReportWithTemporaryMedia} from './Pro/conversion';
import {SubmissionError, submitConditionsReport} from '../../data/ConditionsReportSubmission';
import {ObservableTaskCollection} from '../../data/Task';
import {ObserverStatus} from '../../components/ObserverStatus';
import {Browser} from '@capacitor/browser';
import {environment} from '../../environment';
import {Alert} from '@mui/material';
import {useHistory} from 'react-router';
import {trimEnd, trimStart, useOnlineState} from '../../data/Helpers';
import {ErrorBox} from '../../components/ErrorBox';
import {useI18n} from '../../i18n/i18n';
import {TemporaryImage} from '../../data/images';
import {temporaryFiles} from '../../data/files';

interface SubmitGenericProps {
    cr: ConditionsReportWithTemporaryMedia|null,
    goBack: () => void,
    children?: React.ReactNode,
    deleteInProgressConditionsReport: () => Promise<void>,
}

export const SubmitGeneric: React.FunctionComponent<SubmitGenericProps> = (props) => {
    const history = useHistory();
    const {user} = useLoginState();
    const {label} = useI18n();

    const [tasks, setTasks] = React.useState<ObservableTaskCollection | null>(null);
    const [error, setError] = React.useState<SubmissionError | null>(null);
    const [isOnline] = useOnlineState();

    const submit = async () => {
        setError(null);
        const [tasks, start] = submitConditionsReport(props.cr!, user!.token, label);
        setTasks(tasks);

        const [cr, error] = await start();
        if (error) {
            setError(error);
        } else {
            // Additional logging added to debug error
            try {
                await temporaryFiles.clearStorage();
            } catch (e: unknown) {
                setError({
                    error: e,
                    userFacingMessage: 'temporaryFiles.clearStorage failed',
                    propertyErrors: [],
                });
            }

            try {
                let url = `${trimEnd(environment.powderguideWebsiteUrl, '/')}/conditions-report/${cr.uuid}`;
                if (cr.url) {
                    url = cr.url.indexOf('http') === 0 ? cr.url : `${trimEnd(environment.powderguideWebsiteUrl, '/')}/${trimStart(cr.url, '/')}`;
                }
                await Browser.open({url});
            } catch (e: unknown) {
                setError({
                    error: e,
                    userFacingMessage: 'Browser.open failed',
                    propertyErrors: [],
                });
            }

            try {
                await props.deleteInProgressConditionsReport();
            } catch (e) {
                setError({
                    error: e,
                    userFacingMessage: 'deleteInProgressConditionsReport failed',
                    propertyErrors: [],
                });
            }

            history.push('/');
        }

        setTasks(null);
    };

    let content: JSX.Element;
    if (tasks) {
        content = <div style={{paddingTop: '100px', paddingLeft: '15px', paddingRight: '15px'}}>
            <ObserverStatus observer={tasks}/>
        </div>;
    } else if (props.cr) {
        content = <ConditionsReportPreview conditionsReport={props.cr}/>;
    } else {
        content = <IonLoading/>;
    }

    let footer: React.ReactNode | null = null;
    if (props.cr && !tasks) {
        footer = <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
                <IonButton onClick={props.goBack}>{label('editing.action.back')}</IonButton>
            </div>
            <IonButton
                disabled={!isOnline}
                onClick={() => {
                    // console.log('submitting...');
                    // console.log(props.cr);
                    submit();
                }}
            >
                {label('submit.action.send')}
            </IonButton>
        </div>;
    }

    return <PageWrapper
        title={props.cr?.conditionsReport.title ?? 'Absenden'}
        showBackButton={true}
        onBack={props.goBack}
        footer={footer}
    >
        {error && <SubmissionErrorBox error={error}/>}
        {props.children}

        {content}

        {!isOnline && <Alert severity="warning">
            <h2>{label('submit.error.no_internet.title')}</h2>
            <p>{label('submit.error.no_internet.message')}</p>
        </Alert>}
    </PageWrapper>;
};

interface ConditionsReportPreviewProps {
    conditionsReport: ConditionsReportWithTemporaryMedia;
}

const ConditionsReportPreview: React.FunctionComponent<ConditionsReportPreviewProps> = (props) => {
    const {label} = useI18n();
    const user = useLoginState().user;
    const cr = props.conditionsReport.conditionsReport;
    const temporaryImages = props.conditionsReport.temporaryImages;
    const temporaryVideos = props.conditionsReport.temporaryVideos;

    return <React.Fragment>
        <IonGrid>
            {
                cr.media.length > 0
                    ? <IonImg src={cr.media[0]}/>
                    : <TemporaryImagePreview image={temporaryImages[0]}
                                             bounds={{width: 800, height: 800}}/>
            }
        </IonGrid>

        {cr.type !== 'pro' && <React.Fragment>
            <IonItem>
                <IonLabel>{label('mr.description')}</IonLabel>
                {cr.description}
            </IonItem>
        </React.Fragment>}

        {cr.type === 'pro' && <React.Fragment>
            <IonItem>
                <IonLabel>{label('cr.overall_rating')}</IonLabel>
                <StarSelector value={cr.rating / 2} max={5}/>
            </IonItem>
        </React.Fragment>}
        {cr.type !== 'pro' && <React.Fragment>
            <IonItem>
                <IonLabel>{label('mr.rating')}</IonLabel>
                <StarSelector value={cr.rating} max={5}/>
            </IonItem>
        </React.Fragment>}

        <IonItem>
            <IonLabel>{label('cr.author')}</IonLabel>
            {user?.username}
        </IonItem>

        {cr.type === 'pro' && <React.Fragment>
            <IonItem>
                <IonLabel>{label('cr.snow_condition')}</IonLabel>
                <StarSelector value={cr.snow_condition.rating / 2} max={5}/>
            </IonItem>

            <IonItem>
                <IonLabel>{label('cr.snow_quality')}</IonLabel>
                <StarSelector value={cr.snow_quality.rating / 2} max={5}/>
            </IonItem>

            <IonItem>
                <IonLabel>{label('cr.altitude')}</IonLabel>
                {cr.altitude_lower} - {cr.altitude_upper} m
            </IonItem>
        </React.Fragment>}

        <VideoList conditionsReport={cr} temporaryVideos={temporaryVideos}/>
        <ImageList conditionsReport={cr} temporaryImages={temporaryImages}/>
    </React.Fragment>;
};

interface VideoPreviewProps {
    conditionsReport: ConditionsReport,
    temporaryVideos: TemporaryVideo[],
}

const VideoList: React.FunctionComponent<VideoPreviewProps> = (props) => {
    const {label} = useI18n();

    if (props.temporaryVideos.length === 0 && props.conditionsReport.videos.length === 0) {
        return null;
    }

    return <React.Fragment>
        <IonItemDivider>
            <IonLabel>{label('cr.videos')}</IonLabel>
        </IonItemDivider>
        {/*{props.conditionsReport.video.map(url => <IonItem key={url}>*/}
        {/*    <video src={url}/>*/}
        {/*</IonItem>)}*/}
        {props.temporaryVideos.map((video, index) => <IonItem key={index}>
            <IonLabel>{label('cr.video_num', {number: `${index + 1}`})}</IonLabel>
            <IonThumbnail>
                <TemporaryVideoPreview video={video}/>
            </IonThumbnail>
        </IonItem>)}
    </React.Fragment>;
};

interface MediaPreviewProps {
    conditionsReport: ConditionsReport,
    temporaryImages: TemporaryImage[],
}

const ImageList: React.FunctionComponent<MediaPreviewProps> = (props) => {
    const {label} = useI18n();

    if (props.temporaryImages.length === 0 && props.conditionsReport.media.length === 0) {
        return null;
    }

    return <React.Fragment>
        <IonItemDivider>
            <IonLabel>{label('cr.images')}</IonLabel>
        </IonItemDivider>
        <IonRow>
            {props.conditionsReport.media.map(media => <IonItem key={media}>
                <IonImg src={media}/>
            </IonItem>)}
            {props.temporaryImages.map((image, index) => <IonItem key={index} style={{width: '100%'}}>
                <IonLabel>{label('cr.image_num', {number: `${index + 1}`})}</IonLabel>
                <IonThumbnail>
                    <TemporaryImagePreview image={image}/>
                </IonThumbnail>
            </IonItem>)}
        </IonRow>
    </React.Fragment>;
};

interface SubmissionErrorBoxProps {
    error: SubmissionError,
}

const SubmissionErrorBox: React.FunctionComponent<SubmissionErrorBoxProps> = ({error}) => {
    return <ErrorBox error={error.error} title={error.userFacingMessage}>
        {error.propertyErrors.length > 0 && <ul>
            {error.propertyErrors.map((error, index) => <li key={index}>
                <IonLabel>{error}</IonLabel>
            </li>)}
        </ul>}
    </ErrorBox>;
};
